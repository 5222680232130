<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-appointment"
    :title="Title"
    no-close-on-backdrop
    :hide-footer="true"
    scrollable
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <small>Nombre</small>
      <h6 class="text-muted">
        10-05-1999
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
        Program
      </b-badge>
    </b-card>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Provider :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Site :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Visit Type :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Appt Date :</h6>
      </div>
      <div class="col-9">
        <b-form-group>
          <flat-pickr
            v-model="dateNtim"
            class="form-control"
            :config="{ enableTime: true, minuteIncrement: 1, dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group>
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Coverage :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Interaction Type :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Staff Name :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Appointment Source :</h6>
      </div>
      <div class="col-9">
        <b-form-select
          v-model="ProviderSelected"
          :options="options"
        />
      </div>
    </div>
    <div class="ContenAlign">
      <div class="col-3">
        <h6>Notes :</h6>
      </div>
      <div class="col-9">
        <b-form-textarea
          id="textarea-default"
          placeholder="Notes"
          rows="3"
        />
      </div>
    </div>
    <div
      class="ContenAlign"
      style="    padding-left: 10px;
    padding-right: 10px;"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
      >
        Save
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BAvatar, BBadge, BFormSelect, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCard,
    BBadge,
    BAvatar,
    BFormSelect,
    flatPickr,
    BFormGroup,
    BFormTextarea,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      Title: '',
      Type: '',
      ProviderSelected: null,
      dateNtim: null,
      options: [],
    }
  },
  created() {
    this.$bus.$on('setParamsAppt', param => {
      if (param[0] === 'CR') {
        this.Title = 'Create Appointment'
      } else if (param[0] === 'LV') {
        this.Title = 'Create Last Visit'
      } else if (param[0] === 'ED') {
        this.Title = 'Edit Appointment'
      }
    })
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ContenAlign{
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}
</style>
