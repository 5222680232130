<template>
  <b-tabs pills>
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <kardex-profile-about
          :profile-info-about="profileInfo"
          :profile-appts-hist="apptsHist"
        />
        <kardex-profile-suggested-info :profile-info-suggested="profileInfo" />
      </b-col>
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-tab
          title="Appointment Feed"
          active
        >
          <div id="user-profile">
            <!--/ profile info  -->
            <section id="profile-info">
              <kardex-profile-header :modal-handler-appt="ModalAppointment" />
              <kardex-profile-record
                :profile-stream-appointments="filter"
                :profile-total-appts="totalAppts"
                :modal-handler="ModalAppointmentDetails"
                :filter-handler="filterAppts"
              />
            </section>
            <!--/ profile info  -->
          </div>
        </b-tab>
        <b-tab title="Activity">
          <div>
            <kardex-profile-activity />
          </div>
        </b-tab>
      </b-col>
    </b-row>
    <appointment-detail-modal />
    <appointment-modal />
  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs,
} from 'bootstrap-vue'
import profileService from '@core/services/profiles/profileService'
import AppointmentDetailModal from '@core/components/modals/appointments/AppointmentDetailModal.vue'
import AppointmentModal from '@core/components/modals/appointments/AppointmentModal.vue'
import KardexProfileHeader from './appointmentFeed/KardexProfileHeader.vue'
import KardexProfileAbout from './KardexProfileAbout.vue'
import KardexProfileSuggestedInfo from './KardexProfileSuggestedInfo.vue'
import KardexProfileRecord from './appointmentFeed/KardexProfileRecord.vue'
import KardexProfileActivity from './activity/KardexProfileActivity.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    KardexProfileHeader,
    BTab,
    BTabs,
    KardexProfileAbout,
    KardexProfileSuggestedInfo,
    KardexProfileRecord,
    AppointmentDetailModal,
    AppointmentModal,
    KardexProfileActivity,
  },
  data() {
    return {
      profileInfo: {},
      apptsHist: {},
      profStrmAppts: [],
      totalAppts: {},
      filter: [],
    }
  },
  created() {
    this.getProfilesInfo()
  },
  methods: {
    getProfilesInfo() {
      const { idPatient } = this.$route.params
      profileService.getProfileInfo({
        id: idPatient,
      }).then(response => {
        this.profileInfo = response.data.profInfo
        this.apptsHist = response.data.apptsHist
        this.filter = response.data.profStrmAppts
        this.profStrmAppts = response.data.profStrmAppts
        this.totalAppts = response.data.totalAppts
      })
    },
    ModalAppointmentDetails(id) {
      this.$root.$emit('bv::show::modal', 'modal-appointment-detail')
      this.$bus.$emit('setParams', id)
    },
    ModalAppointment(type, params) {
      this.$root.$emit('bv::show::modal', 'modal-appointment')
      this.$bus.$emit('setParamsAppt', [type, params])
    },
    filterAppts(val) {
      if (val !== 'Show All') {
        this.filter = this.profStrmAppts.filter(obj => obj.Status === val)
      } else {
        this.filter = this.profStrmAppts
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
