<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center mb-2">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search task"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item>
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item>
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item>
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item>
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item>
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <b-row>
        <b-col
          md="3"
          style="padding-right: 0rem;"
        >
          <left-side-bar-activity />
        </b-col>
        <b-col
          md="9"
          style="padding-left: 10px;"
        >
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
          >
            <draggable
              v-model="tasks"
              handle=".draggable-task-handle"
              class="todo-task-list media-list"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <b-list-group-item
                  v-for="(task, index) in tasks"
                  :key="task.id"
                  class="todo-item"
                  :class="{ 'completed': true }"
                  tag="li"
                  :style="index == 0 ? 'border-radius: 10px 10px 0px 0px;' : 'border-radius: 0px 0px 0px 0px;'"
                >
                  <b-row>
                    <b-col md="6">
                      <div
                        class="d-flex"
                        style="align-items: center;"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          class="draggable-task-handle d-inline"
                        />
                        <b-avatar
                          size="32"
                          variant="light-secondary"
                          class="ml-1 mr-1"
                        >
                          <feather-icon
                            icon="ActivityIcon"
                            size="16"
                          />
                        </b-avatar>
                        <span class="todo-title">Aldo Sandoval</span>
                        <div
                          style="font-size: 10px; display: grid;"
                          class="mr-1 ml-1"
                        >
                          <span>Activity Date: 10/02/2022</span>
                          <span>Register Date: 10/02/2023</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-badge
                        pill
                        class="text-capitalize"
                      >
                        Status
                      </b-badge>
                    </b-col>
                  </b-row>

                </b-list-group-item>
              </transition-group>
            </draggable>
          </vue-perfect-scrollbar>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BAvatar, BListGroupItem, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import leftSideBarActivity from './KardexLeftSideBarActivity.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    BListGroupItem,
    leftSideBarActivity,
    BRow,
    BCol,
    BBadge,
  },
  data() {
    return {
      mqShallShowLeftSidebar: useResponsiveAppLeftSidebarVisibility(),
      isTaskHandlerSidebarActive: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      tasks: [
        {
          id: '1', title: 'Team',
        },
        {
          id: '2', title: 'Team',
        },
        {
          id: '3', title: 'Team',
        },
        {
          id: '4', title: 'Team',
        },
        {
          id: '5', title: 'Team',
        },
        {
          id: '6', title: 'Team',
        },
        {
          id: '7', title: 'Team',
        },
        {
          id: '8', title: 'Team',
        },
        {
          id: '9', title: 'Team',
        },
        {
          id: '10', title: 'Team',
        },
        {
          id: '11', title: 'Team',
        },
        {
          id: '12', title: 'Team',
        },
        {
          id: '13', title: 'Team',
        },
        {
          id: '14', title: 'Team',
        },
        {
          id: '15', title: 'Team',
        },
      ],
    }
  },
  methods: {
    resolveTagVariant(tag) {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    },
    resolveAvatarVariant(tags) {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style>
.list-group-item {
  transition: all 1s
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
