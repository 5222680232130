<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-appointment-detail"
    title="Appointment Detail"
    no-close-on-backdrop
    :hide-footer="true"
    scrollable
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <small>{{ Details.NamePatient }}</small>
      <h6 class="text-muted">
        {{ Details.Dob | date-format }}
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
        {{ Details.NameProgram }}
      </b-badge>
      <hr class="mb-2">
      <div class="list text-left">
        <div class="list-item">
          <div class="list-body">
            <p>Appointment set for <b><span class="text-info">{{ Details.ApptDate | date-format }}</span></b> at <b>{{ Details.NameClinic }}</b> with <b>{{ Details.NameProvider }}</b> for a <b>{{ Details.NameProgram }}</b> evaluation
            </p>
            <p>Source: <b>{{ Details.NameSource }}</b> as a
              <b>{{ Details.NameService }}</b>
            </p>
            <small class="block text-muted">created by {{ Details.NameRep }} on {{ Details.ApptRegisterDate | date-format }}</small>
          </div>
        </div>
      </div>
    </b-card>
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        v-for="(record,index) in History"
        :key="index"
        icon="CalendarIcon"
        variant="success"
      >
        <div class="d-flex align-items-start flex-sm-row flex-wrap justify-content-between mb-1 mb-sm-0">
          <small>Appointment Date <b><span class="text-info">{{ record.ApptDate | date-format }}</span></b> </small>
          <b-badge
            pill
            variant="light-primary"
          >
            {{ record['Status Name'] }}
          </b-badge>
        </div>
        <div style="display: grid; margin-top: 15px; margin-bottom: 15px;">
          <small>At: <b><span class="text-info">{{ record.NameClinic }}</span></b> </small>
          <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
            <small>Updated By: <b><span class="text-info">{{ record.NameRep }}</span></b></small>
            <div>
              <feather-icon
                icon="MessageSquareIcon"
                class="mr-1"
              />
            </div>
          </div>
          <small class="block text-muted">Created On: {{ record.ApptRegisterDate | date-format }} </small>
          <small v-if="record.Reason">Reason: <b><span class="text-info">{{ record.Reason }}</span></b></small>
          <small v-if="record.ResqueduledBy">ResqueduledBy: <b><span class="text-info">{{ record.ResqueduledBy }}</span></b></small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BAvatar, BBadge,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import appointmentService from '@core/services/appointments/appointmentService'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BModal,
    BCard,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      History: [],
      Details: [],
    }
  },
  created() {
    this.$bus.$on('setParams', param => {
      this.getHystoryAppts(param)
    })
  },
  methods: {
    getHystoryAppts(param) {
      appointmentService.getAppHistory({
        idAppt: param,
      }).then(response => {
        this.History = response.data.history
        this.Details = response.data.details
      })
    },
  },
}
</script>
