<template>
  <b-card>
    <h5>Information</h5>

    <!-- Information feed -->
    <div
      class="profile-twitter-feed mt-2"
    >
      <div class="d-flex justify-content-start align-items-center mb-1">
        <b-avatar
          size="40"
          src="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/avatar-s-2.da5e73c7.jpg"
          class="mr-1"
        />
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ `${profileInfoAbout.Name} ${profileInfoAbout.LastName} ` }}
          </h6>
          <b-link v-if="profileInfoAbout.ProfileType === 1">
            <small
              class="text-muted"
            >#: {{ profileInfoAbout.Account }}</small>
            <feather-icon
              style="margin-left: 5px"
              icon="CheckCircleIcon"
            />
          </b-link>
          <div v-else>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="sm"
              style="margin-top: 5px"
            >
              <small
                style="font-size: 0.650rem !important;"
                class="align-middle"
              >Convert to Member</small>
            </b-button>
          </div>
        </div>
      </div>
      <b-card-text class="mb-50">
        <h6>Age: {{ profileInfoAbout.Age }}</h6>
        <h6>DOB: {{ profileInfoAbout.Dob | date-format }}</h6>
      </b-card-text>
      <b-link>
        <small class="text-muted ">
          Appts History:
        </small>
        <br>
        <b-row>
          <!-- about suggested page and twitter feed -->
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Scheduled: {{ profileApptsHist.totalScheduled }}</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Re-Scheduled: {{ profileApptsHist.totalReScheduled }}</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Confirmed:  {{ profileApptsHist.totalConfirmed }}</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />No show:  {{ profileApptsHist.totalNoShow }}</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Ocurred:  {{ profileApptsHist.totalOcurred }}</span>
          </b-col>
          <b-col
            lg="6"
            md="4"
            cols="6"
          >
            <span style="font-size: 10px;"><span
              class="fc-event-dot"
              style="background-color: #F8DB7A; margin-right: 0.3rem;"
            />Canceled:  {{ profileApptsHist.totalCanceled }}</span>
          </b-col>
        </b-row>
      </b-link>
    </div>
    <!-- twitter feed -->
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BLink, BCardText, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BAvatar, BLink, BCardText, BRow, BCol, BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    profileInfoAbout: {
      type: Object,
      required: true,
    },
    profileApptsHist: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>
.fc-event-dot{
      display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
}
</style>
