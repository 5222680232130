<template>
  <b-card>
    <!-- filter button group -->
    <div
      class="filterOption2"
      style="display: none; margin-bottom: 25px;"
    >
      <b-form-select
        v-model="selected"
        :options="options"
        size="sm"
      />
    </div>
    <div
      style="margin-bottom: 25px;"
      class="filterOption1"
    >
      <b-button-group
        size="sm"
      >
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-patient"
          @click="filterHandler('Show All')"
        >
          Show All: {{ profileTotalAppts.countShowAll.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-ocurred"
          @click="filterHandler(2)"
        >
          Ocurred: {{ profileTotalAppts.count_2.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-scheduled"
          @click="filterHandler(1)"
        >
          Scheduled: {{ profileTotalAppts.count_1.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-Confirmed"
          @click="filterHandler(6)"
        >
          Confirmed: {{ profileTotalAppts.count_6.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-noShow"
          @click="filterHandler(5)"
        >
          No Show: {{ profileTotalAppts.count_5.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-Re-Scheduled"
          @click="filterHandler(3)"
        >
          Re-Scheduled: {{ profileTotalAppts.count_3.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-Cancelled"
          @click="filterHandler(0)"
        >
          Canceled: {{ profileTotalAppts.count_0.total }}
        </b-button>
        <b-button
          style="font-size: 0.65rem !important;"
          class="btn-outline-ocurred"
          @click="filterHandler(7)"
        >
          Ocurred Last Visit: {{ profileTotalAppts.count_7.total }}
        </b-button>
      </b-button-group>
    </div>
    <!-- filter button group end -->
    <app-timeline>
      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item
        v-for="(profileAppointment, index) in profileStreamAppointments"
        :key="index"
        icon="CalendarIcon"
        variant="success"
      >
        <small class="text-muted">{{ profileAppointment.AppointmentDate | date-format }}</small>
        <div class="d-flex align-items-start flex-sm-row flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ profileAppointment.NameClinic }} <feather-icon
            icon="EditIcon"
            style="margin-left: 5px;"
          /></h6>
          <b-badge
            pill
            variant="light-primary"
            :style="ColorStatus(profileAppointment['Status Name'])"
            @click.prevent="ModalAppointmentDetail(profileAppointment.Id)"
          >
            {{ profileAppointment['Status Name'] }}
          </b-badge>
        </div>
        <div style="display: grid;">
          <small>Coverage Type: {{ profileAppointment.NameProgram }}</small>
          <small>Visit Type: {{ profileAppointment.Service }}</small>
          <small>Appointment Provider: {{ profileAppointment.NameProvider }}</small>
          <small>Notes:
            <feather-icon
              style="margin-left: 5px"
              icon="EditIcon"
              @click="OpenCollapse(index)"
            />
          </small>
          <b-collapse
            :id="`note-list${index}`"
            style="margin-top: 10px; margin-bottom: 10px;"
          >
            <b-form-textarea
              id="textarea-default"
              placeholder="Notes..."
              rows="3"
              :value="profileAppointment.Notes"
              :v-model="profileAppointment.Notes"
            />
          </b-collapse>
          <small>Created On: {{ profileAppointment.AppointmentRegisterDate | date-format }}</small>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BButton, BCollapse, VBToggle, BBadge, VBTooltip, BCard, BButtonGroup, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BButton,
    BCollapse,
    BBadge,
    BCard,
    BButtonGroup,
    BFormSelect,
    BFormTextarea,
  },
  directives: {
    'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple,
  },
  props: {
    profileStreamAppointments: {
      type: Array,
      required: true,
    },
    profileTotalAppts: {
      type: Object,
      required: true,
    },
    modalHandler: {
      type: Function,
      required: true,
    },
    filterHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select an option to filter', disabled: true },
        { value: 'Show All', text: 'Show All' },
        { value: 2, text: 'Ocurred' },
        { value: 1, text: 'Scheduled' },
        { value: 6, text: 'Confirmed' },
        { value: 5, text: 'No Show' },
        { value: 3, text: 'Re-Scheduled' },
        { value: 0, text: 'Canceled' },
        { value: 7, text: 'Ocurred Last Visit' },
      ],
      Streamappts: [],
    }
  },
  watch: {
    selected(val) {
      this.filterHandler(val)
    },
  },
  methods: {
    ModalAppointmentDetail(id) {
      this.modalHandler(id)
    },
    OpenCollapse(index) {
      this.$root.$emit('bv::toggle::collapse', `note-list${index}`)
    },
    ColorStatus(StatusName) {
      switch (StatusName) {
        case 'Ocurred':
          return 'background-color: #3E9FB2; color: #fff !important;'
        case 'Scheduled':
          return 'background-color: #F8DB7A; color: #fff !important;'
        case 'Confirmed':
          return 'background-color: #529B9B; color: #fff !important;'
        case 'No Show':
          return 'background-color: #8474A1; color: #fff !important;'
        case 'Re-Scheduled':
          return 'background-color: #F88973; color: #fff !important;'
        case 'Canceled':
          return 'background-color: #CCABD8; color: #fff !important;'
        case 'Ocurred Last Visit':
          return 'background-color: #3E9FB2; color: #fff !important;'
        default:
          return 'background-color: rgba(38, 34, 98, 0.12);'
      }
    },
  },
}
</script>

<style>
@media (max-width: 768px){
  .filterOption2{
      display: block !important;
  }

  .filterOption1{
      display: none;
  }
}
</style>
