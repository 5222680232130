import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class AppointmentService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAppHistory(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getApptHistory, ...args)
    }
}

function useAppointmentService() {
  const appointmentService = new AppointmentService()

  return {
    appointmentService,
  }
}

const { appointmentService } = useAppointmentService()
export default appointmentService
