<template>
  <b-card>
    <div class="mb-75">
      <small style="font-weight: bold;">Preferred Communications</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.Phone === null ? '' : profileInfoSuggested.Phone | phone-format }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Address</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.Addr1 === null ? '' : profileInfoSuggested.Addr1 }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Transportation</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.Transportation === null ? '' : profileInfoSuggested.Transportation }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Details</small>
      <b-card-text>
        <small>Status: {{ profileInfoSuggested.ProfileType === null ? '' : profileInfoSuggested.ProfileType }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Source</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameSource === null ? '' : profileInfoSuggested.NameSource }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Coverage Type</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameProgram === null ? '' : profileInfoSuggested.NameProgram }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Enrollment Type</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameEnrollementType === null ? '' : profileInfoSuggested.NameEnrollementType }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Prior Health Plan</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NamePriorPlan === null ? '' : profileInfoSuggested.NamePriorPlan }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Health Plan</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameNewPlan === null ? '' : profileInfoSuggested.NameNewPlan }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">IPA</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameIpa === null ? '' : profileInfoSuggested.NameIpa }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Staff Name</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameRep === null ? '' : profileInfoSuggested.NameRep }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Clinic</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.NameClinic === null ? '' : profileInfoSuggested.NameClinic }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Confirmation Number</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.ConfirmationNum === null ? '' : profileInfoSuggested.ConfirmationNum }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">Medi-Cal</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.MedicalNumber === null ? '' : profileInfoSuggested.MedicalNumber }}</small>
      </b-card-text>
    </div>
    <div class="mb-75">
      <small style="font-weight: bold;">PCPC</small>
      <b-card-text>
        <small>{{ profileInfoSuggested.Last === null ? '' : profileInfoSuggested.Last | date-format }}</small>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  props: {
    profileInfoSuggested: {
      type: Object,
      required: true,
    },
  },
}
</script>
